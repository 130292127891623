<template>
  <div class="main-nav">
    <div class="safe-area flex-row">
      <div class="classification-btn flex-row align-items-center justify-center" @mouseenter="!prodClassAlwaysShow&&classShowFun(true)" @mouseleave="!prodClassAlwaysShow&&classShowFun(false)">
        <div style="color:#fff;">药品分类</div>
        <div class="prod-class padding-tb-5" style="background:#fff;position: absolute;top: 50px; left: 0px; z-index: 99; box-shadow: 0 10px 10px rgba(0,0,0,0.1); border-radius: 0 0 10px 10px/0 0 10px 10px;" v-show="classShow">
          <Classification v-if="!prodClassAlwaysShow" @childMouseenter="!prodClassAlwaysShow&&classShowFun(true)" @childMouseleave="!prodClassAlwaysShow&&classShowFun(false)"></Classification>
        </div>
      </div>
      <div class="navs flex-row align-items-center flex-auto">
        <div class="link" @click="$root.jump({path:'/mall/home'})"><span :class="{'active':$route.path=='/mall/home'}">首页</span></div>
        <div class="link" @click="$root.jump({path:'/mall/prodList'})"><span :class="{'active':$route.fullPath=='/mall/prodList'}">推荐商品</span></div>
        <!-- <div class="link" @click="$root.jump({path:'/mall/prodList'})"><span>新品推荐</span></div>
        <div class="link"><span>热销专区</span></div>
        <div class="link"><span>限时秒杀</span></div> -->
      </div>
      <div class="flex-row align-items-end">
        <img height="40" class="margin-top-10" src="@/assets/images/phone.gif" />
        <div class="text-nowrap">
          <div v-for="(item,index) in $store.state.tel" :key="index"><span class="font-size-12">{{item.phone_name}}：</span><span class="color-orange font-size-16 bold">{{item.phone}}</span></div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

import Classification from '@/components/Classification.vue'
export default {
  name: 'HomeNav',
  components: {Classification},
  props:{
    prodClassAlwaysShow:{
      type:Boolean,
      default:true
    },
  },
  data() {
    return {
      classShow:false,
      classHideTimer:null,
      tel:null,
    }
  },
  methods:{
    classShowFun(status){
      if(status){
        this.classShow=true
        clearTimeout(this.classHideTimer)
      }else{
        this.classHideTimer=setTimeout(()=>{ this.classShow=false},500)
      }
    },
  },
  created(){
    
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  .main-nav{
    background: #fff;
    border-bottom: #fe5e12 solid 2px;
    height:50px;
    min-width: 1200px;
    box-sizing: border-box;
    font-size: 16px;
    display: flex;
    .classification-btn{
      background: linear-gradient(to right,#ff7c35,#ff3000);
      width: 200px;
      border-radius: 10px 10px 0 0/10px 10px 0 0;
      position: relative;
      cursor: pointer;
    }
    .navs{
      overflow: auto;
      margin-left: 20px;
    }
    .navs>div{
      padding: 0 20px;
      white-space: nowrap;
      position: relative;
    }
    .active{
      color: #fe5e12;
    }
  }
</style>
